<template>
  <div class="pa-12">
    <div class="card-body pa-3 ma-4 pa-12">
      <div data-tf-widget="BS7Vh7Ei" data-tf-opacity="100" data-tf-iframe-props="title=DapZap" data-tf-transitive-search-params data-tf-medium="snippet" style="width:100%;height:600px;"></div>
    </div>
  </div>
</template>
<script>
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "landingPage",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  components: {},
  mounted() {
  }
};
</script>
<script setup>
let script = document.createElement('script')
script.src = 'https://embed.typeform.com/next/embed.js'
document.body.appendChild(script)
</script>
