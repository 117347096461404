<template>
  <div class="py-2 container-fluid">
    <div class="mt-2 row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
            Iframe example below. (NPM and Cloudflare Plugins coming soon):
          </div>
        </div>
        <iframe width="100%" height="800px" frameborder="0" src="https://widget.dapzap.com/examples"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Widgets",
  components: {},
};
</script>
