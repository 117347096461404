import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import DapZap from "./DapZap";

// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(DapZap);
appInstance.use(vuetify);
appInstance.mount("#app");
