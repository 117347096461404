<template>
  <div class="workflow">
    <div class="mt-0 container-fluid">
      <div class="row">
        <div class="col-12">
          <p class="text-sm">Create automatic workflows based on any on-chain event.</p>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="mb-3 mt-0">Triggers:</h5>
                    <div class="form-check">
                      <input @click="onTokenChange('NFT')" v-model="ruleSource" class="form-check-input" type="radio"
                             value="NFT"
                             name="flexRadioDefault"
                             id="flexRadioDefault1" checked>
                      <label class="form-check-label" for="flexRadioDefault1">
                        NFT Project
                      </label>
                    </div>
                    <div class="form-check">
                      <input @click="onTokenChange('FT')" v-model="ruleSource" class="form-check-input" type="radio"
                             name="flexRadioDefault"
                             value="FT"
                             id="flexRadioDefault2">
                      <label class="form-check-label" for="flexRadioDefault2">
                        FT Token
                      </label>
                    </div>
                    <div v-if="ruleSource==='NFT'" class="mt-4 mb-0 pb-0">
                      <v-autocomplete
                          density="compact"
                          :loading="loading"
                          v-model="selectedProject"
                          label="Choose NFT Project"
                          :items="computedProjects"
                          item-title="name"
                          item-value="id"
                          order
                          variant="outlined"
                          class="mt-0 mb-0 pb-0 font-weight-bold"
                          @update:modelValue="projectSelected"
                      >
                        <template v-slot:item="{ props, item }">
                          <v-list-item
                              v-if="item?.raw?.verified"
                              v-bind="props"
                              :title="item?.raw?.name"
                              subtitle="Verified"
                          >
                          </v-list-item>
                          <v-list-item
                              v-else
                              v-bind="props"
                              :title="item?.raw?.name"
                              :subtitle="item?.raw?.verified"
                          ></v-list-item>
                        </template>
                      </v-autocomplete>

                      <v-autocomplete
                          v-if="selectedProject"
                          density="compact"
                          :loading="loadingTriggers"
                          :disabled="loadingTriggers"
                          v-model="selectedRule"
                          label="Choose Trigger"
                          :items="rules"
                          item-title="name"
                          item-value="id"
                          variant="outlined"
                          class="mt-0 mb-0 pb-0 font-weight-bold"
                          @update:modelValue="selectedTrigger"
                      ></v-autocomplete>
                      <p class="small text-center pa-1 ma-1" v-if="loadingTriggers">Loading events from contract...</p>


                      <v-autocomplete
                          v-if="currentTrigger?.specificNFT"
                          density="compact"
                          v-model="selectedNFT"
                          :disabled="allNftsLoading"
                          :loading="allNftsLoading"
                          :items="allNfts"
                          chips
                          closable-chips
                          color="blue-grey-lighten-2"
                          label="Choose NFT"
                          item-title="PLAYER"
                          return-object
                          variant="outlined"
                          cache-items
                          class="mt-0 mb-0 pb-0 font-weight-bold"
                          style="width: 335px;"
                          @update:modelValue="selectedNFTChanged"
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip v-if="item?.raw?.PLAYER"
                                  class="text-truncate"
                                  v-bind="props"
                                  :text="`${item?.raw?.PLAYER} | Season:${item?.raw?.SEASON} | Category:${item?.raw?.PLAY_CATEGORY}|Type:${item?.raw?.PLAY_TYPE}`"
                          ></v-chip>
                        </template>

                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" v-if="item?.raw?.PLAYER == 'Any'"></v-list-item>
                          <v-list-item
                              class="text-truncate"
                              v-else-if="item?.raw?.PLAYER"
                              v-bind="props"
                              :prepend-avatar="`https://assets.nbatopshot.com/media/${item?.raw?.NFT_ID}/player?quality=90&width=90&height=90`"
                              :title="`${item?.raw?.PLAYER}`"
                              :subtitle="`Season:${item?.raw?.SEASON} | Category:${item?.raw?.PLAY_CATEGORY}|Type:${item?.raw?.PLAY_TYPE}`"
                          >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <div v-if="selectedNFT?.TOTAL_CIRCULATION && !this.newsList.length >0">
                        Serial Number Range
                        <v-chip class="" small>{{ serialRange[0] }} - {{ serialRange[1] }}</v-chip>
                        <v-range-slider
                            color="success"
                            v-model="serialRange"
                            step="10"
                            hint
                            :max="selectedNFT?.TOTAL_CIRCULATION"
                            :min="1"
                            class="mb-0 pb-0 mt-0"
                        ></v-range-slider>
                      </div>
                      <div class="px-2">
                        <v-card v-if="selectedNFT.PLAYER" class="mb-2 mt-1">
                          <div class="ma-3">
                            <h4 class="mb-0">{{ selectedNFT.PLAYER }}</h4>
                            <span class="text-sm">Season:{{ selectedNFT.SEASON }} | Category: {{
                                selectedNFT.PLAY_CATEGORY
                              }} |Type: {{ selectedNFT.PLAY_TYPE }}</span>
                          </div>
                          <v-img
                              :src="`https://assets.nbatopshot.com/media/${selectedNFT.NFT_ID}/player?quality=100&width=150&height=150`"></v-img>
                        </v-card>


                        <v-card v-if="newsList.length >0" class="mb-2 mt-1 pa-4">
                          Latest News:
                          <v-list-item v-for="(news, idx) in newsList" :key="idx">
                            {{ news.title }}
                            <a href="{{news.url}}" class="font-weight-bold">Read more</a>
                            <hr>
                          </v-list-item>
                        </v-card>

                      </div>
                    </div>
                    <div v-else-if="ruleSource==='FT'" class="form mb-4">
                      <div>Token: {{ selectedProject }}</div>
                      <select v-model="selectedProject" class="form-control">
                        <option disabled value="">Choose FT</option>
                        <option>FLOW</option>
                        <option>DUST</option>
                      </select>
                    </div>
                    <div v-if="ruleSource">
                      <div v-if="showToAddress && currentTrigger?.address">
                        <div>To Address:</div>
                        <input type="text" name="name" id="To Address" v-model="trigger.toAddress"
                               class="form-control mb-1 mt-1"/>
                      </div>
                      <div v-if="showFromAddress">
                        <div>From Address:</div>
                        <input type="text" name="name" id="To Address" v-model="trigger.fromAddress"
                               class="form-control mb-0 mt-1"/>
                      </div>
                    </div>
                    <div v-if="currentTrigger?.price" class="ma-0 pa-0">
                      <v-row class="mt-0">
                        <v-col cols="6" class="mt-0">
                          <div>Min Price:</div>

                          <input type="number" name="name" id="minPrice" v-model="trigger.minPrice"
                                 class="form-control mb-1 mt-0"/>
                        </v-col>
                        <v-col cols="6" class="mt-0">
                          <div>Max Price:</div>
                          <input type="number" name="name" id="maxPrice" v-model="trigger.maxPrice"
                                 class="form-control mb-2 mt-0"/>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="mx-auto text-center mt-2">
                      <button class="btn btn-success text-white mx-auto text-center" type="button"
                              :disabled="!selectedNFT.PLAYER"
                              @click="addNode('Trigger', selectedNFT.PLAYER, selectedRule.name)">Add
                        Trigger
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 static">
                <div class="card">
                  <div class="card-body">
                    <h5 class="mb-5 mt-0">Workflow:</h5>
                    <input type="text" name="name" id="name" v-model="dapzap.name" class="form-control mb-4"/>
                    <VueFlow v-model="savedRule" :connection-radius="50" auto-connect fit-view-on-init>
                      <template
                          #connection-line="{ sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }">
                        <ConnectionLine
                            :source-x="sourceX"
                            :source-y="sourceY"
                            :target-x="targetX"
                            :target-y="targetY"
                            :source-position="sourcePosition"
                            :target-position="targetPosition"
                        />
                      </template>
                    </VueFlow>
                  </div>
                  <div class="mx-auto mx-4 text-center">
                    <div class="mt-0 mb-1">Polling Interval:</div>
                    <div class="row">
                      <div class="col-md-6">
                        <select v-model="trigger.pollPeriod" class="form-control" disabled>
                          <option value="Minutes">Minutes</option>
                          <option value="Hours">Hours</option>
                          <option value="Days">Days</option>
                          <option value="Weeks">Weeks</option>
                          <option value="Months">Month</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <input type="number" name="name" id="pollInterval" v-model="trigger.pollInterval"
                               class="form-control mb-2" disabled/>
                      </div>
                    </div>
                    <small>Trigger will be checked every {{ trigger.pollInterval }} {{ trigger.pollPeriod }}, <a
                        href="/pricing" class="font-weight-bold">Upgrade</a> to change.</small>
                    <br>

                    <button class="btn btn-success text-white ma-4" type="button" @click="save(false)">Save</button>
                    <button class="btn btn-info text-white ma-4" type="button" @click="save(true)">Create New
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-body">
                    <h5 class="mb-5 mt-0">Actions:</h5>
                    <select v-model="selectedActions" class="form-control mb-4">
                      <option disabled value="">Choose Action</option>
                      <option v-for="action in actions" :key="action.name" :value="action.code">{{
                          action.name
                        }}
                      </option>
                    </select>
                    <div v-if="selectedActions == 'discord' || selectedActions == 'slack'">
                      <div>To Channel:</div>
                      <input type="text" name="name" id="name" v-model="action.toAddress"
                             class="form-control mb-4 mt-1"/>
                      <div>User Id:</div>
                      <input type="text" name="name" id="name" v-model="action.userID"
                             class="form-control mb-3 mt-1"/>
                    </div>
                    <div v-if="selectedActions == 'email'">
                      <div>To Address:</div>
                      <input type="text" name="name" id="name" v-model="action.toAddress"
                             class="form-control mb-1 mt-1"/>
                    </div>

                    <div v-if="selectedActions == 'sms'">
                      <div>Mobile Number:</div>
                      <input type="text" name="name" id="name" v-model="action.toNumber"
                             class="form-control mb-1 mt-1"/>
                    </div>


                    <div>Message:</div>
                    <input type="text" name="name" id="name" v-model="action.message" class="form-control mb-4 mt-1"/>
                    <div class="mx-auto text-center">
                      <button class="btn btn-success text-white" type="button"
                              @click="addNode('Action', selectedActions,'')">Add
                        Action
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useVueFlow, VueFlow} from '@vue-flow/core'
import ConnectionLine from './components/SnappableConnectionLine.vue'

const {nodes, addNodes, addEdges, onConnect} = useVueFlow()

onConnect((params) => addEdges([params]))

const addNode = (name, item, rule) => {
  const nodeId = (nodes.value.length + 1).toString()
  let y = Number(nodeId) * 80
  let position = {x: 10, y: y}
  const defaultNodeStyle = {
    border: '2px solid #2a56dc',
    borderRadius: '99px',
  }
  if (name === 'Action') {
    console.log('action')
    position = {x: 220, y: y}
    defaultNodeStyle.border = '2px solid #4b915a'
  }

  let wname = `${name}: ${item} ${rule}`

  const newNode = {
    id: nodeId,
    label: `${wname}`,
    position: position,
    style: defaultNodeStyle,
  }

  addNodes([newNode])
}
</script>
<script>
import axios from "axios";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import _ from 'lodash';

const fcl = require("@onflow/fcl");
fcl.config()
    .put("accessNode.api", "https://damp-clean-butterfly.flow-mainnet.quiknode.pro/415aa5330524674715f1f7eb5b3b539aad79326a/");
// .put("accessNode.api", "https://access-mainnet-beta.onflow.org");


const db = firebase.firestore();

export default {
  name: "workflowbuilder",
  components: {},
  data() {
    return {
      user: this.$store.state.user,
      dapzap: {
        name: "DapZap Name",
        description: "DapZap Description",
      },
      loading: true,
      loadingTriggers: false,
      nodeFunctionsURL: "http://127.0.0.1:5001/dapzap-d97a8/us-central1",
      //pythonFunctionsURL: "https://us-central1-dapzap-d97a8.cloudfunctions.net/python-http-function",
      pythonFunctionsURL: "http://localhost:9000",
      selectedRule: '',
      rules: [],
      defaultRules: [
        {
          "name": "Minted",
          "code": "minted"
        },
        {
          "name": "Listed",
          "code": "listed"
        },
        {
          "name": "Bought",
          "code": "bought"
        },
        {
          "name": "Sold",
          "code": "sold"
        },
        {
          "name": "Traded",
          "code": "traded"
        },
        {
          "name": "Staked",
          "code": "staked"
        },
        {
          "name": "Unstaked",
          "code": "unstaked"
        },
        {
          "name": "Burnt",
          "code": "burned"
        },
        {
          "name": "Floor Price",
          "code": "floor"
        },
        {
          "name": "News & Sentiment",
          "code": "news"
        },
      ],
      triggers: [],
      currentTrigger: '',
      selectedActions: '',
      actions: [
        {
          "name": "Send Email",
          "code": "email"
        },
        {
          "name": "Send Discord Post",
          "code": 'discord',
        },
        {
          "name": "Send Tweet",
          "code": 'twitter',
        },
        {
          "name": "Send SMS",
          "code": "sms"
        },
        {
          "name": "Send to Slack",
          "code": "slack"
        },
        {
          "name": "Create CSV",
          "code": "csv"
        },
        {
          "name": "Mark on Calendar",
          "code": "calendar"
        },
        {
          "name": "Mint NFT",
          "code": 'mint'
        },
        {
          "name": "List NFT",
          "code": "list"
        },
        {
          "name": "Custom Webhook",
          "code": "webhook"
        },
      ],
      selectedProject: '',
      selectedProjectData: {},
      projects: [],
      tokens: ['FLOW', 'USDC', 'DUST', 'THUL'],
      ruleSource: '',
      savedRule: this.addNodes,
      selectedNFT: '',
      serialRange: '',
      search: '',
      allNfts: [],
      allNftsLoading: false,
      trigger: {
        toAddress: '',
        fromAddress: '',
        minPrice: null,
        maxPrice: null,
        pollPeriod: 'Minutes',
        pollInterval: 30,
      },
      triggerData: {},
      showToAddress: false,
      showFromAddress: false,
      newsList: [],
      action: {
        toAddress: '',
        fromAddress: '',
        userID: '',
        channelId: '',
        listPrice: null,
        message: '',
      },
    }
  },
  computed: {
    computedProjects() {
      return this.projects
    }
  },
  mounted() {
    this.getAllowList()
    //this.getMyFlowCatalog()
    this.onTokenChange('NFT')
  },
  methods: {
    onTokenChange(value) {
      console.log(value)
      this.ruleSource = value
      this.selectedProject = ''
    },
    save(newRule) {
      if (!this.user?.isLoggedin) {
        this.router.push('/signin')
      } else {
        this.$store.dispatch('saveRule', this.savedRule, newRule)
      }
    },
    async getAllFlowCatalog() {
      this.loading = true
      await db.collection("flowNftCollectionCatalog").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().verified) {
            let verified = ({id: doc.id, name: doc.id, verified: true, contract: doc.data().contract})
            this.projects = [verified, ...this.projects]
          } else {
            this.projects.push({id: doc.id, name: doc.id, verified: false, contract: doc.data().contract})
          }

        });
      }).then(() => {
        this.loading = false
      });
    },
    getMyFlowCatalog() {
      console.log('get flow catalog')
      this.pfpList = []
      this.getAllowList().then(() => {
        const url = `${this.nodeFunctionsURL}/getnftcollections?flowAddress=${this.flowWallet}`
        axios.get(url)
            .then(response => {
              Object.entries(response.data[0]).forEach(item => {
                if (this.showInventory) {
                  if (this.allowList[item[0]]?.inventoryOnly) {
                    this.pfpList.push({id: item[0], name: this.allowList[item[0]].displayName})
                    this.loading = false
                  }
                } else if (this.allowList[item[0]] && !this.allowList[item[0]]?.disabled && this.allowList[item[0]] && !this.allowList[item[0]]?.inventoryOnly) {
                  this.pfpList.push({id: item[0], name: this.allowList[item[0]].displayName})
                  this.loading = false
                } else {
                  console.log("not on allowlist", item[0])
                }
              })
            })
            .catch(error => {
              console.log(error)
              this.loading = false
            })
      })
    },
    async getAllowList() {
      this.loading = true
      await db.collection('settings')
          .doc('supportedCollections')
          .get()
          .then(snapshot => {
            this.allowList = snapshot.data()
            this.getAllFlowCatalog()
          })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    async projectSelected() {
      console.log('project selected')
      if (this.selectedProject) {
        console.log(this.selectedProjectData)
        await db.collection("flowNftCollectionCatalog").doc(this.selectedProject).get().then((doc) => {
          if (doc.exists) {
            if (doc.data()?.triggers) {
              this.rules = []
              this.triggerData = doc.data()
              this.triggerData.id = doc.id
              this.triggers = this.triggerData?.triggers
              let triggers = Object.keys(doc.data().triggers).map((k) => doc.data().triggers[k])
              triggers.forEach(trigger => {
                console.log('trigger', trigger)
                this.rules.push(trigger)
              })
            } else {
              console.log('no custom triggers')
              this.rules = this.defaultRules
              this.getContractEvents()
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            this.rules = this.defaultRules
          }
        }).then(() => {
          this.loading = false
        });
      }
    },
    async selectedTrigger() {
      console.log('selectedTrigger')
      console.log(this.selectedRule)
      console.log(this.triggers["news"])
      this.allNftsLoading = true
      if (this.selectedRule) {
        this.currentTrigger = this.triggers[this.selectedRule.toLowerCase()]
        console.log('this.currentTrigger', this.currentTrigger)
        if (this.triggerData?.cachedNFTs) {
          this.downloadCachedNFTs()
        }
      }

      console.log(this.selectedRule)
      if (this.selectedRule == "Listed") {
        this.getNFTs().then(() => {
          this.downloadCachedNfts()
        })
      }

      if (this.triggerData?.nfts) {
        // this.getNFTs()
      } else {
        console.log(this.allNfts)
      }
    },
    async getNFTs() {
      console.log('get nfts')
      this.allNftsLoading = true
      const contract = "A." + this.selectedProjectData.contract + "." + this.selectedProjectData.name
      const url = `${this.pythonFunctionsURL}/get_all_nfts/?projectName=${contract}`
      axios.get(url)
          .then(response => {
            this.allNfts = response.data
            console.log(this.allNfts)
            this.upload(this.allNfts)

          })
          .catch(error => {
            console.log(error)
            this.allNftsLoading = false
          })
    },
    downloadCachedNfts() {
      console.log('get cached nfts')
      const storage = firebase.app().storage().ref()
      let bucketLocation = `nftlistings/${this.selectedProject}.json`
      const storageRef = storage.child(bucketLocation)
      storageRef.getDownloadURL().then((url) => {
        console.log(url)
        axios.get(url).then((response) => {
          console.log(response)
          this.allNfts = response.data
          console.log(this.allNfts)
          this.allNftsLoading = false
        })
      })
    },
    upload(jsonData) {
      const storage = firebase.app().storage().ref()
      let bucketLocation = `nftlistings/${this.selectedProject}.json`
      const storageRef = storage.child(bucketLocation)
      var blob = new Blob([JSON.stringify(jsonData)], {type: "application/json"})
      // upload you blob into the storage
      storageRef.put(blob).then(() => {
        console.log('Uploaded a nft list json! ' + bucketLocation);
      });
    },
    async latestNBANews() {
      let newsParams = _.unescape(this.currentTrigger.function.replace("{{this.selectedNFT.PLAYER}}", this.selectedNFT?.PLAYER).split(" ").join(""))
      console.log(newsParams)
      //const options = {newsParams};
      const options = {
        method: 'GET',
        url: 'https://nba-latest-news.p.rapidapi.com/articles',
        params: {
          player: 'James Harden'
        },
        headers: {
          'X-RapidAPI-Key': '09d3620e99mshad5ee7d3344b744p1c3cc9jsnbba61fd9b92d',
          'X-RapidAPI-Host': 'nba-latest-news.p.rapidapi.com'
        }
      };
      try {
        const response = await axios.request(options);
        console.log(response.data);
        this.newsList = response.data
      } catch (error) {
        console.error(error);
      }
    },
    selectedNFTChanged() {
      this.serialRange = [1, this.selectedNFT?.TOTAL_CIRCULATION]
      if (this.selectedProject === "TopShot" && this.selectedNFT?.PLAYER) {
        this.latestNBANews()
      }
    },
    getContractEvents() {
      this.loadingTriggers = true
      console.log('get contract events')
      this.projects.forEach(project => {
        if (project.id === this.selectedProject) {
          this.selectedProjectData = project
        }
      })
      const contract = "A." + this.selectedProjectData.contract + "." + this.selectedProjectData.name
      const url = `${this.pythonFunctionsURL}/get_contract_events/?contractId=` + contract
      console.log(url)
      axios.get(url)
          .then(response => {
            this.rules = []
            response.data.forEach(event => {
              console.log(event)
              this.rules.push({name: event.event_type, code: event.event_type})
              this.rules.push({name: "Listed", code: "Listed"})
            })
            this.loadingTriggers = false
            console.log(this.rules)
          })
          .catch(error => {
            console.log(error)
          })

    }
  }
};
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.15.2/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.15.2/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';

.vue-flow {
  margin: auto;
  padding: 20px;
  width: 600px;
  max-width: 95%;
  height: 400px;
  border: 3px solid #a1d04c6b;
  border-radius: 5px;
  background: #c9e07fad;
}

.basicflow.dark {
  background: #57534e;
  color: #fffffb
}

.basicflow.dark .vue-flow__node {
  background: #292524;
  color: #fffffb
}

.basicflow.dark .vue-flow__controls .vue-flow__controls-button {
  background: #292524;
  fill: #fffffb;
  border-color: #fffffb
}

.basicflow.dark .vue-flow__edge-textbg {
  fill: #292524
}

.basicflow.dark .vue-flow__edge-text {
  fill: #fffffb
}

.basicflow .controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px
}

.basicflow .controls button {
  padding: 4px;
  border-radius: 5px;
  font-weight: 600;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
  box-shadow: 0 5px 10px #0000004d;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center
}

.basicflow .controls button:hover {
  transform: scale(102%);
  transition: .25s all ease
}

.v-list-item-subtitle {
  font-size: 9px !important;
}

.v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-input--dirty.v-slider.v-range-slider.v-locale--is-ltr.mb-0.pb-0 {
  height: 35px;
}

.vue-flow__handle, .vue-flow__handle.vue-flow__handle-top.vue-flow__handle-2__handle-top.nodrag.nopan.target.connectable.connectablestart.connectableend.connectionindicator,
.vue-flow__handle.vue-flow__handle-top.vue-flow__handle-1__handle-top.nodrag.nopan.target.connectable.connectablestart.connectableend.connectionindicator {
  width: 10PX;
  height: 10px;
  background: green !important;
}
</style>