<template>
  <div class="activity">
    <div class="mt-0 container-fluid">
      <div class="row">
        <div class="col-12">
          <v-data-table
              :loading="loading"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items="feedData"
          ></v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {VDataTable} from 'vuetify/labs/VDataTable'
import axios from "axios";
import '@mdi/font/css/materialdesignicons.css'

export default {
  name: "livefeed",
  components: {VDataTable},
  data() {
    return {
      feedData: [],
      loading: true,
      itemsPerPage: 10,
      headers: [
        {title: 'Project', align: 'end', key: 'nft project name'},
        {title: 'Marketplace', align: 'end', key: 'marketplace'},
        {title: 'Bought', align: 'end', key: 'count_buys'},
        {title: 'Total Buy Price', align: 'end', key: 'buy_usd_volume'},
        {title: 'Sold', align: 'end', key: 'count_sells'},
        {title: 'Total Sell Price', align: 'end', key: 'sell_token_volume'},
      ],
      pythonFunctionsURL: "https://us-central1-dapzap-d97a8.cloudfunctions.net/python-http-function",
      //pythonFunctionsURL: "http://localhost:9000",
      flowWallet: "0x3cbb4897e52fcc31",
    }
  },
  mounted() {
    this.getLiveFeed();
  },
  methods: {
    getLiveFeed() {
      this.loading = true;
      const url = `${this.pythonFunctionsURL}/get_all_collections/?queryId=user-account-activity&variableName1=User_Flow_Address&variableValue1=${this.flowWallet}&save=false`
      axios.get(url)
          .then(response => {
            this.feedData = response.data
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.loading = false
          })

    }
  },
};
</script>

