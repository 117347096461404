<template>
  <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
  >
    <span class="mask opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h2 class="mt-5 mb-2">Coming Soon!</h2>
          <p class="text-lead">
            Developer API docs coming soon!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>
