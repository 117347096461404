<template>
  <footer class="footer">
    © {{ new Date().getFullYear() }} DapZap
  </footer>
</template>
<style>
.footer.footer {
  position: fixed;
  bottom: 3px;
  right: 20px;
}
</style>

<script>
export default {
  name: "app-footer",
};
</script>
