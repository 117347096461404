<template>
  <div class="py-3 pr-10 container-fluid">
    <div class="mb-2">
      <h2>DapZap is the Zapier for Web3</h2>
      <h4>Create triggers & automate tasks based on any on chain event</h4>
    </div>
    <div class="row">
      <v-row class="mb-6">
        <v-col cols="6">
          <div class="card-body p-3 hero text">
            <p>DapZap is a web3 automation tool that allows you to connect various applications and automate tasks
              between
              them.</p>
            <p>Here are some of the things that DapZap is good for:</p>
            <ul>
              <li><b>Integrating with multiple apps</b>: With DapZap, you can easily integrate multiple apps and
                automate
                workflows between them. This allows you to streamline your work processes and keep all your tools in
                sync.
              </li>
              <li><b>Creating customized workflows</b>: DapZap offers a range of pre-built workflows but you can also
                create
                your own custom workflows based on your specific needs.
              </li>
              <li><b>Improving productivity</b>: By automating tasks and workflows, DapZap can help you and your team be
                more productive and efficient.
              </li>
              <li><b>Automating repetitive tasks</b>: DapZap can help you automate tasks like data entry, file
                management,
                and analytics, which can save you a lot of time and effort.
              </li>
              <li><b>Enhancing collaboration</b>: DapZap can also help teams collaborate more effectively by automating
                tasks like sending notifications, updating data in real-time, and creating team-wide alerts.
              </li>
            </ul>
            DapZap is a powerful tool for automating and streamlining processes, improving productivity, and
            enhancing collaboration across web3 and web applications.
          </div>
        </v-col>
        <v-col cols="6">
          <v-img
              class="w-100 position-relative z-index-2 mx-auto mb-0 pt-0"
              max-width="700px"
              src="/workflow-builder.png"
              alt="DapZap web3"
          />
          <h4 class="mx-auto text-center mb-1 bold">Common Triggers</h4>
          <v-chip-group class="mx-auto mb-5 text-center">
            <v-chip class="mx-auto mb-5 text-center">Emails Alerts</v-chip>
            <v-chip class="mx-auto mb-5 text-center">SMS Alerts</v-chip>
            <v-chip class="mx-auto mb-5 text-center">Post to Discord</v-chip>
            <v-chip class="mx-auto mb-5 text-center">Post to Slack</v-chip>
            <v-chip class="mx-auto mb-5 text-center">Create CSV</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">DapZap is the Zapier for Web3</p>
                  <h5 class="font-weight-bolder">No Code, Low Code, and REST APIs to get to market fast.</h5>
                  <p class="mb-5">
                    Add Prebuilt wallet-less and Flow/Dapper login UI, PFP pickers and custom marketplace listings.
                    Level up your Web2 or Web3 projects in minutes.
                  </p>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-success border-radius-lg h-100">
                  <img
                      src="../assets/img/shapes/waves-white.svg"
                      class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                      alt="waves"
                  />
                  <div
                      class="position-relative d-flex align-items-center justify-content-center h-100"
                  >
                    <img
                        class="w-100 position-relative z-index-2 pt-4"
                        src="../assets/img/illustrations/rocket-white.png"
                        alt="rocket"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
              class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
              style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Works with any NFT, FT or contract event on Flow Blockchain
                </h5>
                <p class="text-white mb-5">
                  Easily automate tasks, and create custom workflows for collectors and customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-table/>
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
            class="h-100"
            title="Recently Processed DapZaps">
          <timeline-item
              color="success"
              icon="bell-55"
              title="Test Slack"
              date-time="24 FEB 7:20 PM"
          />
          <TimelineItem
              color="info"
              icon="cart"
              title="NFT Purchased"
              date-time="24 FEB 9:34 PM"
          />
          <TimelineItem
              color="warning"
              icon="credit-card"
              title="Flow Purcahsed"
              date-time="25 FEB 2:20 AM"
          />
          <TimelineItem
              color="info"
              icon="check-bold"
              title="First DapZap Created"
              date-time="24 FEB 3:20 PM"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>
import {faCreditCard, faHandPointer, faScrewdriverWrench, faUsers,} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "landingPage",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  components: {},
};
</script>
<style>
.hero.text {
  font-size: 14px;
}
</style>
